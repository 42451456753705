<template>
  <div class="g__item  g-6  g-tablet-12  c-finance__block">
    <h6 class="c-finance__block-title">INTEREST FREE 0% APR</h6>
    
    <div class="g-init  g-init--no-gutter">
      <div class="g__item  g-4">
        <h6 class="c-finance__block-key">Duration</h6>
        <ul class="c-finance__block-list o-list">
          <li class="c-finance__block-item  o-list__item">6 months</li>
          <li class="c-finance__block-item  o-list__item">10 months</li>
          <li class="c-finance__block-item  o-list__item">12 months</li>
        </ul>
      </div>
      <div class="g__item  g-4">
        <h6 class="c-finance__block-key">Monthly</h6>
        <ul class="c-finance__block-list o-list">
          <li class="c-finance__block-item  o-list__item">&pound{{six_months}}</li>
          <li class="c-finance__block-item  o-list__item">&pound{{ten_months}}</li>
          <li class="c-finance__block-item  o-list__item">&pound{{twelve_months}}</li>
        </ul>
      </div>
      <div class="g__item  g-4">
        <h6 class="c-finance__block-key">Total</h6>
        <ul class="c-finance__block-list o-list">
          <li class="c-finance__block-item  o-list__item">&pound{{total}}</li>  <!-- Total -->
          <li class="c-finance__block-item  o-list__item">&pound{{total}}</li>  <!-- Total -->
          <li class="c-finance__block-item  o-list__item">&pound{{total}}</li>  <!-- Total -->
        </ul>
      </div>
    </div>

  </div>
</template>


<script>
  function money(value) {
    return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  export default {
    props: ['fa'],
    computed: {
      total: function() {
        return (this.fa);
      },
      six_months: function() {
        return money((this.fa)/6);
      },
      ten_months: function() {
        return money((this.fa)/10);
      },
      twelve_months: function() {
        return money((this.fa)/12);
      },
      six_daily_months: function() {
        return money((this.fa)/182.5);
      },
      ten_daily_months: function() {
        return money((this.fa)/304.167);
      },
      twelve_daily_months: function() {
        return money((this.fa)/365);
      }  
    }
  }
</script>