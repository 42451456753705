var loadMoreCareer = function () {
  var $ = require('jquery');
  
  var $trigger = $('.js-load-more-career');
  var $container = $('.c-careers__wrapper');

  if($trigger.length) {
    $trigger.click(function(e) {
      e.preventDefault();

      $.get($(this).attr('href') + '/', function(data) {
        var $link = $(data).filter('.ajax-pagination-link');
        if($link.length) {
          $trigger.attr('href', $link.text());
        } else {
          $trigger.parent().remove();
        }
        
        $container.append($(data).not('.ajax-pagination-link'));
      });
    });
  }
};


module.exports = loadMoreCareer;