var loadMore = function loadMore() {

	var $ = require('jquery');

	if ($('.js-load-more').length) {

		var $trigger = $('.js-load-more');
		var $container = $('.c-news__wrapper');

		if($trigger.length) {

			$('.js-load-more').on('click', function(e) {

				e.preventDefault();

				$.get($(this).attr('href') + '/', function(data) {

					var $link = $(data).filter('.ajax-pagination-link');
					var href = $link.find('a').attr('href');

					if($link.length) {
						$trigger.attr('href', href);
					} else {
						$trigger.parent().remove();
					}

					$container.append($(data).not('.ajax-pagination-link'));
					
				});
			});

		}

	}

	// $('.js-load-more').click(function(){
	// 	var $this = $(this);
	// 	var $link = $this.attr('data-link');
	// 	var $container = $('.c-latest-wrapper');

	// 	$.get($link, function(data) {
	// 		var $data = $(data).find('.c-latest-wrapper').html();

	// 		var $new = ($(data).find('.js-load-more') ? $(data).find('.js-load-more').attr('data-link') : false)

	// 		$container.append($data);

	// 		if ($new) {
	// 			$this.attr('data-link', $new);
	// 		} else {
	// 			$this.remove();
	// 		}
	// 	});

	// });
};

module.exports = loadMore;