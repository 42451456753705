var clinicFilter = function () {
    var $ = require('jquery');

    var reset = function() {
        $(".js-clinic-block").show();
    };


    $(".js-clinic-filter").change(function(){
        // Reset
        reset();
        
        // Cache This
        var $this = $(this);

        // Set treatment ID
        // var treatment_id = $(".js-treatment-filter").val();

        // Set Location
        var location = $(".js-location-filter").val();

        // if (treatment_id != 'null') {
        //     $(".js-clinic-block").each(function(index, value){
        //         if ($(this).attr('data-treatments').indexOf(treatment_id) < 0) {
        //             $(this).hide();
        //         }
        //     });
        // }

        if (location != 'null') {
            $(".js-clinic-block").each(function(index, value){
                if ($(this).attr('data-location') != location) {
                    $(this).hide();
                }
            });
        }

        $(".c-clinic__title").matchHeight();
    });
    
};
module.exports = clinicFilter;