var finance = function () {
  var $ = require('jquery');
  var Vue = require('vue');




  //VUE
  var Finance = require('./components/Finance.vue');
  Vue.config.devtools = false
  Vue.config.debug = false
  Vue.config.silent = true
  
  new Vue({
    el: '#finance',
    render: h => h(Finance)
  });



  $(".o-header-finance").mousedown(function(e){
    var subject = $(".o-header-finance .o-container");
    if(e.target.id != $(".o-header-finance .o-container") && !subject.has(e.target).length) {
      $(".o-header-finance").removeClass('active');
    }
  });

}

module.exports = finance;