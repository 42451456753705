var treatment = function () {
  var $ = require('jquery');
  require('slick-carousel');


  $(".js-treatment").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    infinite: false,
    responsive: [
    {
      breakpoint: 425,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    ]
  });


  $(".js-treatments").slick({
    arrows: false,
    dots: true,
    autoplay: false,
    infinate: true,
  });


  $(".js-latest").slick({
    arrows: false,
    dots: true,
    autoplay: false,
    infinate: true,
  });

  // $('.treatment .c-treatment-top__button').on('click', function(e){
  //   e.preventDefault();
  //   $('.js-enquire').click();
  // });


  $(".c-treatment-menu__trigger").on("click", function(){
    $(this).toggleClass('isActive');
    $(this).next(".c-treatment-menu__list").slideToggle();
  });
}

module.exports = treatment;
