<template>
  <div class="c-finance">
    <span class="c-finance__close  js-video-close"><i class="material-icons">clear</i></span>
    
    <div class="o-row">
      <h2 class="c-finance__title">Finance Calculator</h2>
      <h6 class="c-finance__message">FINANCE REQUIRED</h6>
      <div class="c-finance__prefix">&pound
        <input v-model="financeAmount"  maxlength="5" class="c-finance__amount" min="500" max="30000" value="3000" step="10" type="text" pattern="\d*">
      </div>
      <!-- <input type="range"  min="500" max="30000" step="10" value="3000" > -->
      <range-slider
        class="slider"
        min="500"
        max="30000"
        step="10"
        v-model="financeAmount">
      </range-slider>
    </div>
    
    <div class="o-row">
      <div class="g-init">
        <finance-zero v-if="financeAmount" :fa="financeAmount"></finance-zero>
        <finance-zero v-else :fa="0"></finance-zero>

        <finance-apr v-if="financeAmount" :fa="financeAmount"></finance-apr>
        <finance-apr v-else :fa="0"></finance-apr>
      </div>
    </div>
    <br>
    <p>Perfect Smile Surgery Limited trading as Perfect Smile with its registered address at Perfect Smile, 25-27 Kew Road, Richmond, TW9 2NQ, United Kingdom is authorised and regulated by the Financial Conduct Authority and entered on the Financial Services Register, reference number 668640.</p>

  </div>
</template>


<script>
// Imports
var RangeSlider = require('vue-range-slider')
var AprFinance = require('./AprFinance.vue')
var ZeroFinance = require('./ZeroFinance.vue')

export default {
  name: 'finance',
  components: {
    'finance-zero': ZeroFinance,
    'finance-apr': AprFinance,
     RangeSlider
  },
  data() {
    return {
      financeAmount: 3000
    }
  }
}
</script>