var bannerMap = function map(key) {
  var $ = require('jquery');
  var GoogleMapsLoader = require('google-maps');
  var $map = $('.js-banner-map');

  if(!$map.length) return;
    GoogleMapsLoader.KEY = key;
    GoogleMapsLoader.load(function(google) {
        $('.js-banner-map').each(function(index, value){
              var cords = new google.maps.LatLng(parseFloat($(this).attr('data-lat')), parseFloat($(this).attr('data-lng')));

              var map = new google.maps.Map($(this)[0], {
                center: cords,
                zoom: 13,
                scrollwheel: false
              });

              var gmark = new google.maps.Marker({
                position: cords,
                map: map,
              });

              // map.event.trigger(map, "resize");
        });
    });
  
}

module.exports = bannerMap;