<template>
  <div class="g__item  g-6 g-tablet-12  c-finance__block">
    <h6 class="c-finance__block-title">PART SUBSIDISED 9.9% APR</h6>
    
    <div class="g-init  g-init--no-gutter">
      <div class="g__item  g-4">
        <h6 class="c-finance__block-key">Duration</h6>
        <ul class="c-finance__block-list o-list">
          <li class="c-finance__block-item  o-list__item">36 months</li>
          <li class="c-finance__block-item  o-list__item">48 months</li>
          <li class="c-finance__block-item  o-list__item">60 months</li>
        </ul>
      </div>
      <div class="g__item  g-4">
        <h6 class="c-finance__block-key">Monthly</h6>
        <ul class="c-finance__block-list o-list">
          <li class="c-finance__block-item  o-list__item">&pound{{short_months}}</li>
          <li class="c-finance__block-item  o-list__item">&pound{{medium_months}}</li>
          <li class="c-finance__block-item  o-list__item">&pound{{long_months}}</li>
        </ul>
      </div>
      <div class="g__item  g-4">
        <h6 class="c-finance__block-key">Total</h6>
        <ul class="c-finance__block-list o-list">
          <li class="c-finance__block-item  o-list__item">&pound{{short_total}}</li>  <!-- Total -->
          <li class="c-finance__block-item  o-list__item">&pound{{medium_total}}</li>  <!-- Total -->
          <li class="c-finance__block-item  o-list__item">&pound{{long_total}}</li>  <!-- Total -->
        </ul>
      </div>
    </div>

  </div>
</template>


<script>

  function money(value) {
    return value.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  export default {
    props: ['fa'],
    computed: {
      short_total: function() {
        return money((this.fa*0.032024)*36);
      },
      medium_total: function() {
        return money((this.fa*0.025114)*48);
      },
      long_total: function() {
        return money((this.fa*0.020992)*60);
      },

      short_months: function() {
        return money(this.fa*0.032024);
      },
      medium_months: function() {
        return money(this.fa*0.025114);
      },
      long_months: function() {
        return money(this.fa*0.020992);
      },

      short_daily_months: function() {
        return money((this.fa*0.032024)/30.42);
      },
      medium_daily_months: function() {
        return money((this.fa*0.025114)/30.42);
      },
      long_daily_months: function() {
        return money((this.fa*0.020992)/30.42);
      }  
    }
  }
</script>