var $ = require('jquery');
var _ = require('lodash');

var loadMore = require('./load-more');
var treatment = require('./treatment');
var loadMoreCareer = require('./load-more-career');
var homeMap = require('./homeMap');
var clinicMap = require('./clinics-map');
var bannerMap = require('./bannerMap');
var footerMap = require('./footerMap');
var clinicFilter = require('./clinic-filters');
var homeBanner = require('./homeBanner');
var finance = require('./finance');
require('slick-carousel');
var shave = require('shave');
require('jquery-match-height');

$(function(){
	homeBanner();
	loadMore();
	treatment();
	loadMoreCareer();
	finance();
	clinicFilter();
	homeMap(window.__CONFIG__.google_api_key);
	clinicMap(window.__CONFIG__.google_api_key);
	bannerMap(window.__CONFIG__.google_api_key);
	footerMap(window.__CONFIG__.google_api_key);

	//Shave Text
	shave('.c-post__intro', 80);
	shave('.c-post__hover-title', 80);
	shave('.c-post__title', 140);


	//Match Height
	$(".c-clinic-block__match").matchHeight();
	$(".c-treatment__title").matchHeight();
	$(".c-treatment__intro").matchHeight();
	$(".c-career__title").matchHeight();
	$(".c-before-after__block-content").matchHeight();
	$(".c-before-after__block-title").matchHeight();
	$(".c-clinic__title").matchHeight({byRow: false});
	$(".c-clinic-block__title").matchHeight({byRow: false});
	$(".c-price-block__coverage").matchHeight();
	$(".js-video-match").matchHeight();
	$(".c-treatment").matchHeight();
	$(".c-treatment--alt").matchHeight();
	$(".c-benefit__title").matchHeight();
	$(".c-benefit__text").matchHeight();
	$(".c-clinic-block__list").matchHeight();
	$(".c-clinic-block__match").matchHeight();
	$(".c-team").matchHeight();
		$(".c-team__title").matchHeight();
		$(".c-team__about").matchHeight();
	$(".c-featured-blocks__title").matchHeight();
	$(".c-featured-blocks__content").matchHeight();
	$(".c-featured-blocks__sub").matchHeight();
	$(".c-featured-blocks__small").matchHeight();
	
	$(".c-pricing-row__price").matchHeight();
	$(".c-pricing-row__top").matchHeight();
	$(".c-pricing-row__main").matchHeight();

	$(".c-logos__up").click(function(){
		$("html, body").animate({ scrollTop: 0 }, "slow");
		return false;
	});



	// Contact Numbers Popup

	$(".js-contact-numbers").click(function(){
		$(".c-contact-popup").fadeIn();
	});

	$(".c-contact-popup__close").click(function(){
		$(".c-contact-popup").fadeOut();
	});

	$(".js-contact-region").click(function(){
		var $id = $(this).attr('data-region');
		$(".c-contact-popup__title").html('Choose a Clinic');
		$(".c-contact-popup__key").hide();
		$(".c-contact-popup__clinics").show();
		$('.c-contact-popup__item[data-region="'+ $id +'"]').show();
	});

	$(".js-contact-back").click(function(){
		var $id = $(this).attr('data-region');
		$(".c-contact-popup__title").html('Choose a Region');
		$(".c-contact-popup__clinics").hide();
		$(".c-contact-popup__item").hide();
		$(".c-contact-popup__key").show();
	});


	//Search
	$(".js-search").click(function(){
		$(".o-header__search").toggleClass('active');
		$(".c-header-search__input").focus();
	});

	$("body").mouseup(function(e){
		var subject = $(".o-header__search");
		if(e.target.id != $(".o-header__search") && !subject.has(e.target).length) {
			$(".o-header__search").removeClass('active');
		}
	});


	//Slick Numebers Homepage
	$(".js-numbers").slick({
		'arrows': false,
		'dots': true,
		'autoplay': true,
		'infinate': true,
		autoplaySpeed: 2500,
	});


	$(".js-numbers-2").slick({
		'arrows': false,
		'dots': true,
		'autoplay': true,
		'infinate': true,
		autoplaySpeed: 3000,
	});



	//Dropdowns
	$(".c-dropdown__selected").click(function(){
		$(this).siblings(".c-dropdown__options").slideToggle();
		$(this).addClass('active');
	});


	//Video
	$(".js-video-player").click(function() {
		var $this = $(this);
		var video = $this.next(".js-video-src").data('video');
		$this.next(".js-video-src").attr('src', video);
		$this.hide();
	}); 



	$(".js-treatment-video").click(function() {
		var $this = $(this);
		console.log($this.prev("i.c-treatment__video-icon"));
		$this.prev("i.c-treatment__video-icon").hide();
		var video = $this.next(".c-treatment__video").data('video');
		$this.next(".c-treatment__video").attr('src', video);
		$this.hide();
	}); 


	//SUB NAV
	// $(".c-nav__item").hover(function(){
	//   $this =  $(this);
	//   if ($this.children('ul').length) {
	//     $($this.children(".c-nav__link")).hover(function(){
	//       var height = $this.children('ul').height();
	//       $this.children('ul').delay(300).addClass('active');
	//       $(".o-header-overlay").height(height + 140);  
	//     }); 
	//   }
	// }, function(){
	//   if ($this.children('ul').length) {
	//     $this.children('ul').removeClass('active');
	//     $(".o-header-overlay").height(0);   
	//   }   
	// });

	$(".c-nav__link").on('mouseenter', function(){

		if ($(this).parent().hasClass('level-0') && !$(this).parent().hasClass('active')  && $(this).parent().hasClass('has-children')) {
			$('.c-sub-nav').removeClass('active');
			$('.c-nav__item').removeClass('active');
			$(this).parent().addClass('active');
			$(this).parent().children('ul').addClass('active');
			var height = $(this).parent().children('ul').height();
			$(".o-header-overlay").height(height + 215); 
		} 

		if ($(this).parent().hasClass('level-0') && !$(this).parent().hasClass('active')) {
			$('.c-sub-nav').removeClass('active');
			$('.c-nav__item').removeClass('active');
			var resetHeight = _.debounce(function() {
					$(".o-header-overlay").height(0)
			});
			resetHeight();
		}

	});

	$('.c-treatment-top__link').on('mouseenter', function(){
		$('.c-treatment-top__link').css({ opacity: '0.5', transition : '0.5s ease-in-out' });
		$(this).css({ opacity: '1' });
	});

	$('.c-treatment-top__link').on('mouseout', function(){
		$('.c-treatment-top__link').css({ opacity: '1' });
	});

	$(".o-header").mouseleave(function(){
		var resetHeight = _.debounce(function() {
				$(".o-header-overlay").height(0)
		});
		resetHeight();
		$('.c-nav__item').removeClass('active');
		$('.c-sub-nav').removeClass('active');
	});



	//Video
	$('.js-video').click(function(){
		$('.o-header-video').fadeIn();
		var url = "https://www.youtube.com/embed/" + $(this).data('video') + '?autoplay=1';
		console.log(url);
		$('.o-header-video').find('iframe').attr('src', url);
	});

	$('.js-video-close').click(function(){
		$('.o-header-video').fadeOut();
		$('.o-header-video').find('iframe').attr('src','');
	});



	$('.js-finance').click(function(){
		$('.o-header-finance').addClass('active');
	});

	$('.c-finance__close').click(function(){
		$('.o-header-finance').removeClass('active');
	});



	$(".c-price-block__coverage").click(function() {
		$(this).siblings(".c-price-block__list").slideToggle();
		$(this).toggleClass('active');
	});


	$(".js-enquire").click(function(){
		$(".c-enquiry-popup").fadeIn();
	});


	$(".js-clinic-email").on("click", function(){
		$('html, body').animate({
			scrollTop: $("#footer-form").offset().top
		}, 1000);

		var $val = '';
		var $clinic = $(this).attr('data-name');

		if ($clinic == 'Ham') {
			$val = 'Ham - Ashburnham Rd'
		} else {
			$val = $clinic;
		}

		$("#nf-field-385").val($val);
	});

	$(".c-enquiry-popup__close").click(function(){
		$(".c-enquiry-popup").fadeOut();
	});


	$(window).scroll(function() {    
			var scroll = $(window).scrollTop();
			if (scroll >= 230) {
				var $header = $(".c-logos");
				if (!$header.hasClass('clinic')) {
					$header.addClass("fixed");
					$(".c-treatment-menu").addClass("fixed");
				}
			} else {
				$(".c-logos").removeClass("fixed");
				$(".c-treatment-menu").removeClass("fixed");
			}

			if (scroll >= 1000) {
					$('.js-mobile-nav-wrap ').slideUp();
			}
	});

	$(window).scroll(function() {    
		var scroll = $(window).scrollTop();
		var bannerHeight = $('.c-banner').height();
		var contentHeight = $('.c-clinic__calc-height').height();
		var heightToFix = 180 + (bannerHeight + contentHeight);
		if (scroll >= heightToFix) {
			$(".c-clinic-nav").addClass("fixed");
		} else {
			$(".c-clinic-nav").removeClass("fixed");
		}

		if (scroll >= 1000) {
			// $('.js-mobile-nav-wrap ').slideUp();
		}
	});

	$(".c-clinic-tabs__item").click(function(){
		$(".c-clinic-tabs__item").removeClass('active');
		$(".c-clinic-intro").removeClass('active');
		var tab = $(this).attr('data-tab');
		$(this).addClass('active');
		$(".c-clinic-intro").each(function(index, value){
			if ($(this).attr('data-tab') == tab) {
				$(this).addClass('active');
				$(this).show();
			}
		});
	});



	$(".c-glossary__item").click(function(){
			$(".c-glossary__item").removeClass('active');
			$(".c-glossary__section").removeClass('active');
			var tab = $(this).attr('data-tab');
			$(this).addClass('active');
			$(".c-glossary__section").each(function(index, value){
					if ($(this).attr('data-tab') == tab) {
							$(this).addClass('active');
					}
			});
	});


	$(".c-glossary__select").change(function(){
			$(".c-glossary__section").removeClass('active');
			var tab = $(this).val();
			$(".c-glossary__section").each(function(index, value){
					if ($(this).attr('data-tab') == tab) {
							$(this).addClass('active');
					}
			});
	});



	$(".c-accordions__dropdown").click(function(){
			var $this = $(this);

			function resetDropdowns() {
					$this.next(".c-accordions__content").slideUp();
					$this.removeClass('active');
			}

			if (!$this.hasClass('active')) {
				$this.addClass('active');
				$this.next(".c-accordions__content").slideDown();
				$this.parent().find('.js-banner-map').each(function() {
					if ($(this).attr('loaded') != '1') {
						initializeGoogleMaps($(this));
					}
					$(this).attr('loaded', '1');
				})
			} else {
				resetDropdowns();
			}

	});

		function initializeGoogleMaps(map) {

			var cords = new google.maps.LatLng(parseFloat(map.attr('data-lat')), parseFloat(map.attr('data-lng')));

			var map = new google.maps.Map(map[0], {
				center: cords,
				zoom: 13,
				scrollwheel: false
			});

			var gmark = new google.maps.Marker({
				position: cords,
				map: map,
			});

		}

		var $menu = $('.js-mobile-nav-wrap ');
		var $wrap = $menu.find('.c-mobile-nav');
		var $back = $menu.find('.js-back');
		var $current = null;
		var level = 0;

		$(".js-mobile-menu").click(function(){
				$("html, body").animate({ scrollTop: 0 }, "slow");
				$menu.slideToggle();
		});

		$back.click(function(e) {
				level--;
				$wrap.animate({
						left: '+=100%'
				}, function() {
						if(level === 0) {
								$wrap.find('ul').hide();
						} else {
								if($current) $current.hide();
								$current = (level < 1 && $current) ? $current.parents('ul') : null;
						}
				});
		});

		$menu.find('li').not('.js-back').click(function(e) {
				e.stopPropagation();
				var $target = $(e.target);
				if($target.is('.js-back') || $target.parents('.js-back').length || $target.is('a')) return;
				level++;

				var $this = $(this);
				if(!$this.find('> ul').length) return;

				$current = $this.find('> ul');
				$current.show();

				$wrap.animate({
						left: '-=100%'
				});
		});





var topHeight = $(".c-logos").innerHeight();

if($(".c-treatment-menu").length) {
	var menuHeight = $(".c-treatment-menu").innerHeight();
}

var height = topHeight + menuHeight;

$(window).resize(function(){
	var topHeight = $(".c-logos").innerHeight();

	if($(".c-treatment-menu").length) {
		var menuHeight = $(".c-treatment-menu").innerHeight();
	}

	var height = topHeight + menuHeight;
});

$('a[href*="#"]')
	// Remove links that don't actually link to anything
	.not('[href="#"]')
	.not('[href="#0"]')
	.click(function(event) {
		// On-page links
		if (
			location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') 
			&& 
			location.hostname == this.hostname
		) {
			// Figure out element to scroll to
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			// Does a scroll target exist?
			if (target.length) {
				// Only prevent default if animation is actually gonna happen
				event.preventDefault();
				$('html, body').animate({
					scrollTop: target.offset().top - 160
				}, 1000, function() {
					// Callback after animation
					// Must change focus!
					var $target = $(target);
					if ($target.is(":focus")) { // Checking if the target was focused
						return false;
					} else {
						$target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
						// $target.focus(); // Set focus again
					};
				});
			}
		}
	});


	//Slick Numebers Homepage
	$(".c-before-after__wrapper").slick({
		arrows: false,
		dots: false,
		autoplay: false,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
	});


	$('.scroll_notice').click(function(){
		$('.c-before-after__wrapper').slick('slickNext');
	});



	$(".js-grid-items").slick({
		arrows: false,
		dots: true,
		centerMode: false,
		autoplay: false,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			}
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			}
		}
		]
	});

	if ($(".c-treatment--grid").length > 3) {
		// $(".c-grid-blocks__swipe").show();
		$(".js-grid-items .slick-dots").show();
	}

	$(".c-grid-blocks__swipe").on('click', function(){
		$(".js-grid-items").slick('slickNext');
	});



	$(".js-see-more").click(function() {
		var abilities_content = '';
		var $image = $(this).attr('data-image');
		var $name = $(this).attr('data-name');
		var $job = $(this).attr('data-job');
		var $about = $(this).attr('data-about');
		var $abilities = $(this).siblings('.c-clinic-team--abilities');

		if ($abilities.length) {
			abilities_content += '<h3 class="c-team-popup__specialist-title">Specialist in:</h3>';
			$abilities.children().each(function(index) {
				abilities_content += '<a href="' + $(this).data('url') + '" class="c-btn c-btn--primary">' + $(this).data('title') + '</a>';
			});
		}
		$('.c-team-popup__abilities').html(abilities_content);

		$(".js-team").fadeIn();
		$(".c-team-popup__title").html($name);
		$(".c-team-popup__job").html($job);
		$(".c-team-popup__about").html($about);
		$(".c-team-popup__image").attr('src', $image);
	});

    $('.c-team-popup__inner').on('click', function(e) {
        e.stopPropagation();
    });

    $('.c-team-popup, .c-team-popup__container').on('click', function(e) {
        var target = $(e.target);
        if (!target.is('.c-team-popup__inner')) {
            $(".js-team").fadeOut();
        }
    });

	$(".c-team-popup__close").click(function(){
		$(".js-team").fadeOut();
	});


	$(".c-treatment-menu--mobile .c-treatment-menu__link").on("click", function(){
		$(".c-treatment-menu__trigger").removeClass('isActive');
		$(".c-treatment-menu--mobile .c-treatment-menu__list").slideUp();
	});


	Object.defineProperty(Date.prototype, 'YYYYMMDDHHMMSS', {
		value: function() {
			function pad2(n) {  // always returns a string
				return (n < 10 ? '0' : '') + n;
			}

			return this.getFullYear() +
					pad2(this.getMonth() + 1) + 
					pad2(this.getDate()) +
					pad2(this.getHours()) +
					pad2(this.getMinutes()) +
					pad2(this.getSeconds());
		}
	});

	if($('.c-countdown__timer').length){
		var startTime = new Date().YYYYMMDDHHMMSS();
		startTime = parseInt(startTime) - (parseInt($('.js-mins').text()) + parseInt($('.js-hours').text())); // - this stuff to prevent it from going into minus time, as it appeared to go to - X number of minutes, added the hour too just to be safe in case someone is one 1 page for over an hour
		var endTime = parseInt($('.c-countdown__timer').attr('data-time'));

		var countdownTimer = setInterval(function(){
			if(endTime > startTime){
				var second = parseInt($('.c-countdown__timer  .js-secs').text());
				var minute = parseInt($('.c-countdown__timer .js-mins').text());
				var hour = parseInt($('.c-countdown__timer .js-hours').text());
				var day = parseInt($('.c-countdown__timer .js-days').text());
				if(second !== 0){
					$('.js-secs').text((second - 1));
				}else{
					$('.js-secs').text('59');
					if(minute !== 0){
						$('.js-mins').text((minute - 1));
					}else{
						$('.js-mins').text('59');
						if(hour !== 0){
							$('.js-hours').text((hour - 1));
						}else{
							$('.js-hours').text('23'); 
							$('.js-days').text((day - 1));
						}
					}
				}
				startTime++;
			}else{
				clearInterval(countdownTimer);
			}
		},1000);
	}

});




$(window).scroll(function() {
	if (!$(".c-home__slide-number").length) return;
	var top_of_element = $(".c-home__slide-number").offset().top;
	var bottom_of_element = $(".c-home__slide-number").offset().top + $(".c-home__slide-number").outerHeight();
	var bottom_of_screen = $(window).scrollTop() + window.innerHeight;
	var top_of_screen = $(window).scrollTop();

	if((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
		$('.c-home__slide-number.inactive').each(function(){
			$(this).removeClass('inactive');
			var number = $(this).attr("data-val");

			$(this).prop('Counter',0).animate({
				Counter: number
			}, {
				duration: 3000,
				easing: 'swing',
				step: function (now) {
					$(this).text(commaSeparateNumber(Math.round(now)));
				}
			});
		});
	}

	function commaSeparateNumber(val){
		while (/(\d+)(\d{3})/.test(val.toString())){
			val = val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
		}
		return val;
	}
});



$(window).on("load", function() {
	if ($("#footer-form").attr("data-treatment")) {
		var $val = '';
		var $treatment = $("#footer-form").attr("data-treatment");
		
		if ($treatment == 'Invisalign (Clear Braces)') {
			$val = 'Invisalign';
		} else {
			$val = $treatment;
		}
		
		jQuery("#nf-field-388").val($val).trigger( 'change' );
	}


	$('.js-reviews-widget').attr('src', $('.js-reviews-widget').attr('data-src'));
	
	$('.clinic-select').on('change', function() {
		if ($(this).val() == 'Not Sure') {
			$('.ninja-hiden').addClass('isActive');
		} else {
			$('.ninja-hiden').removeClass('isActive');
		}
 	});
});