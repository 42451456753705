var homeBanner = function () {
    var $ = require('jquery');
    require('slick-carousel');

    $(".c-home-banner__slider").slick({
      autoplay: true,
      dots: true,
      infinite: true,
      arrows: false,
      pauseOnHover: false,
      autoplaySpeed: 4000,
    });
}
  
  module.exports = homeBanner;