var clinicMap = function (key) {
  var $ = require('jquery');
  var GoogleMapsLoader = require('google-maps');
  var $map = $('.js-clinic-map');

  if(!$map.length) return;

  GoogleMapsLoader.LIBRARIES = ['geometry', 'places'];
  GoogleMapsLoader.KEY = key;
  GoogleMapsLoader.load(function(google) {

    var bounds = new google.maps.LatLngBounds();
    var isDraggable = $(document).width() > 480 ? true : false;

    var map = new google.maps.Map($map[0], {
      center: new google.maps.LatLng(51.507368, -0.128142),
      zoom: 10,
      draggable: isDraggable,
      scrollwheel: false,
      disableDefaultUI: false,
      styles:  [{"featureType":"landscape.man_made","elementType":"all","stylers":[{"color":"#faf5ed"},{"lightness":"0"},{"gamma":"1"}]},{"featureType":"poi.park","elementType":"geometry.fill","stylers":[{"color":"#bae5a6"}]},{"featureType":"road","elementType":"all","stylers":[{"weight":"1.00"},{"gamma":"1.8"},{"saturation":"0"}]},{"featureType":"road","elementType":"geometry.fill","stylers":[{"hue":"#ffb200"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"lightness":"0"},{"gamma":"1"}]},{"featureType":"transit.station.airport","elementType":"all","stylers":[{"hue":"#b000ff"},{"saturation":"23"},{"lightness":"-4"},{"gamma":"0.80"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#a0daf2"}]}]
    });

    var pins = [];

    var closeAllPins = function(){
      pins.forEach(function(object){
        object._info.close(map, object._gmark);
      });
    };


    function newLocation(newLat,newLng)
    {
      map.setCenter({
        lat : newLat,
        lng : newLng
      });
    }

    $(".c-clinics__tabs-item").click(function(){
    var $this = $(this);
    $(".c-clinics__wrapper").removeClass('active');
    $(".c-clinics__tabs-item").removeClass('active');
    $this.addClass('active');
    var data = $this.data('tab');
    $('.c-clinics__tabs-item[data-tab="'+ data +'"]').addClass('active');

    $(".c-clinics__wrapper").each(function(){
      if ($(this).data('item') == data) {
        $(this).addClass('active');

        if (data == 1) {
          newLocation(51.507368, -0.128142);
          map.setZoom(10);
        }
    
        if (data == 2) {
          newLocation(54.9621271, -1.630279);
          map.setZoom(12);
        }
    
        if (data == 3) {
          newLocation(53.521396, -2.498372);
          map.setZoom(11);
        }
    
        if (data == 4) {
          newLocation(50.8197675, -1.0879769000000579);
          map.setZoom(12);
        }
    
        if (data == 5) {
          newLocation(53.408371, -2.991573);
          map.setZoom(11);
        }
          
          if (data == 6) {
              newLocation(51.481728, -0.613576);
              map.setZoom(11);
          }

          if (data == 7) {
              newLocation(53.189999, -2.890000);
              map.setZoom(11);
          } 
      }
    });

    $(".c-clinic-block__match").matchHeight();
		$(".c-clinic-block__list").matchHeight();
  });


    $.ajax('/wp-json/wp/v2/clinics/?per_page=100', {
      success: function(data) {
        data.forEach(function(mark) {
          var gmark = new google.maps.Marker({
            position: {
              lat: parseFloat(mark.acf.map.lat),
              lng: parseFloat(mark.acf.map.lng)
            },
            icon:'/wp-content/themes/pm-child/images/icon.png',
            map: map,
          });
          // bounds.extend(gmark.position);

          var info = new google.maps.InfoWindow({
            content:'<i class="fa fa-times"></i><p>' + mark.acf.clinic_name + '</p><a href="tel:' + mark.acf.phone_number + ' ">' + mark.acf.phone_number + '</a><ul><li>' + mark.acf.address_1 + '</li><li>' + mark.acf.address_2 + '</li><li>' + mark.acf.address_3 + '</li><li>' + mark.acf.postcode + '</li></ul><a href="' + mark.link + '">See Clinic</a>',
            pixelOffset: new google.maps.Size(135,175),
            closeBoxURL: ""
          });

          pins.push( {
            _gmark : gmark,
            _info : info
          } );
          
          gmark.addListener('click', function() {
            closeAllPins();
            info.open(map, gmark);
            $(".close-infobox").click(function(){
              closeAllPins();
            });
          });

          info.addListener('domready', function() {
            $('.gm-style-iw').prev().remove().end();
          });

          info.addListener('domready', function() {
              $(".gm-style-iw i").click(function(){
                closeAllPins();
              });
          });
            });
            // map.fitBounds(bounds);
            $( ".gm-style-iw" ).parent('div').css( "width", "100%!important" );
          }
      });
  });

};
module.exports = clinicMap;